var HomePage;
(function (HomePage) {
    function Init(stateCode, assemblyVersion, statesArray) {
        $(function () {
            $("#autocomplete-category").focus();
            $("#foundCouncilHide").on("click", function () {
                PageSetup.SetCookie("hide-council-selector", $("#foundCouncilHide").is(":checked"), 365);
            });
            if (stateCode) {
                Common.Leaflet.Init(stateCode, assemblyVersion, statesArray);
            }
        });
    }
    HomePage.Init = Init;
})(HomePage || (HomePage = {}));
